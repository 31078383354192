import { BurgerMenuComponent } from './../burger-menu/burger-menu.component';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetConfig,
} from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { DataTransferService } from '../../core/service/data-transfer/data-transfer.service';
import { TokenService } from '../../core/service/tokenService/token.service';
import { ScreamService } from '../../core/service/screamService/scream.service';
import { MatDialog } from '@angular/material/dialog';
import { ScreamStatusConfirmationComponent } from '../scream-status-confirmation/scream-status-confirmation.component';
import { ViewContactComponent } from './view-contact/view-contact.component';
import { DeleteScreamComponent } from '../delete-scream/delete-scream.component';
import { ReportComponent } from '../report/report.component';

@Component({
  selector: 'app-scream',
  templateUrl: './scream.component.html',
  styleUrls: ['./scream.component.scss'],
})
export class ScreamComponent implements OnInit, AfterViewInit {
  @ViewChild('showMoreContent') showMoreContent!: ElementRef;

  data: any;
  myScream: any;
  isCardOpen = false;
  bottomSheetRef: any;
  isCardOpens = false;
  taggedBrands: any;

  constructor(
    private _bottomSheet: MatBottomSheet,
    public router: Router,
    private readonly dataTransferService: DataTransferService,
    public tokenService: TokenService,
    public screamService: ScreamService,
    private matDialog: MatDialog,
    public dialog: MatDialog,
    private elementRef: ElementRef
  ) {}

  screamShimmer = false;
  @Input() profile: any;
  @Input() scream: any;
  @Input() image: any;
  @Input() screamStatus: any;
  @Input() experienceStatus: any;
  userProfileImage: any;
  profileImage: any;
  currentUserName: any;
  currentBrandName: any;
  blobStorageLink = this.dataTransferService.blobUrl;
  defaultImage = this.dataTransferService.defaultImage;
  isUserLoggedIn = this.dataTransferService?.isLoggedin();
  isBusinessLoggedIn = this.dataTransferService?.isBusinessLoggedin();
  businessDefaultImage = this.dataTransferService.businessDefaultImage;
  noOfScreamLines!: number;
  showMoreButton!: boolean;

  screamEmotion = [
    'frustrated',
    'mad',
    'furious',
    'angry',
    'disappointed',
    'satisfied',
    'good',
    'happy',
    'joyful',
    'delighted',
  ];
  brandName: any;
  displayedStatus: any;

  ngOnInit(): void {
    if (this.scream.mentionedBrands) {
      this.taggedBrands = this.scream.mentionedBrands
        .split(',')
        .map((brand: string) => brand.replace(/~/g, ' '));
    } else {
      this.taggedBrands = [];
    }
    const storedBrandName = this.dataTransferService?.getBrandDetails();
    this.currentBrandName = JSON.parse(storedBrandName).brandName;
    this.tokenService.getShowMore();
    this.noOfScreamLines = JSON.stringify(this.scream.scream)?.split(
      '\\n'
    ).length;
    this.userProfileImage = this.dataTransferService.getEditProfileImage();
    const userDetails = this.dataTransferService.getUserDetails();
    this.currentUserName = userDetails ? JSON.parse(userDetails)?.userName : '';
    if (this.dataTransferService?.getUserDetails()) {
      this.profile = JSON.parse(
        this.dataTransferService?.getUserDetails() || ''
      );
    }
    this.myScream =
      this.scream?.loginResponseDTO?.userName === this.profile?.userName
        ? true
        : false;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.calculateLines();
    }, 0);
  }

  calculateLines() {
    const lineHeight = parseInt(
      getComputedStyle(this.showMoreContent.nativeElement).lineHeight,
      10
    );
    const lines = Math.ceil(
      this.showMoreContent.nativeElement.scrollHeight / lineHeight
    );
    this.showMoreButton = lines > 4;
  }

  disableScroll() {
    document.body.classList.add('cdk-global-scrollblock');
  }
  enableScroll() {
    document.body.classList.remove('cdk-global-scrollblock');
  }

  replaceMatchingValues(brands: any, scream: any): any[] {
    const modifiedScream: string[] = scream.map((item: any) => {
      if (item === '') {
        return '*#/s';
      } else {
        return item;
      }
    });
    for (let i = 0; i < brands?.length; i++) {
      const valueToMatch = brands[i];
      for (let j = 0; j < scream?.length; j++) {
        if (scream[j] === '@' + valueToMatch) {
          modifiedScream[
            j
          ] = `<a class="highlighted pointer" >@${valueToMatch}</a>`;
        }
      }
    }

    return modifiedScream;
  }

  expandScream() {
    this.scream.showMore = !this.scream?.showMore;
    this.tokenService.setShowMore(this.scream.showMore);
    if (this.scream?.showMore) {
      this.showMoreContent.nativeElement.classList.remove('truncate');
      this.showMoreContent.nativeElement.classList.add('expand');
    } else {
      this.showMoreContent.nativeElement.classList.remove('expand');
      this.showMoreContent.nativeElement.classList.add('truncate');
    }
  }

  highlightBrand(brands: string, scream: string): any {
    let highlightedScream = JSON.parse(
      JSON.stringify(scream).replace(/\\n/g, '<br> ')
    ).replaceAll('\u00A0', ' ');
    const splittedScream = highlightedScream.split(' ');
    highlightedScream = this.replaceMatchingValues(
      brands?.split(','),
      splittedScream
    ).join(' ');

    return highlightedScream
      .replaceAll('~', ' ')
      .replaceAll('*#/s', '&nbsp;') as string;
  }

  @HostListener('click', ['$event'])
  onClickingBrand(event: MouseEvent) {
    const target = event.target as HTMLAnchorElement;
    if (event.target instanceof HTMLAnchorElement === false) {
      return;
    }
    event.preventDefault();
    const brandName = target?.textContent?.substring(1);
    if (this.isUserLoggedIn) {
      this.router.navigate([`brand/${brandName}`]);
      window.scroll(0, 0);
    }
  }

  openBottomSheet(scream: any) {
    if (
      this.dataTransferService.isLoggedin() ||
      this.dataTransferService.isBusinessLoggedin()
    ) {
      this.tokenService.setContent(true);
      const config: MatBottomSheetConfig = {
        data: { scream: scream },
        panelClass: ['custom-bottom-sheet'],
      };
      this._bottomSheet.open(BurgerMenuComponent, config);
    } else {
      this.router.navigate(['/login']);
    }
  }
  openDelete() {
    const dialogRef = this.dialog.open(DeleteScreamComponent, {
      width: '300px',
      panelClass: ['border-radius'],
      autoFocus: false,
      data: { scream: this.scream },
    });
  }
  openDeleteCard() {
    if (this.isCardOpen) {
      this.isCardOpen = false;
    } else {
      this.isCardOpen = true;
    }
  }

  handleIconClick() {
    if (!this.myScream) {
      this.openReportCard();
    } else {
      this.openDeleteCard();
    }
  }
  openReportCard() {
    this.tokenService.setContent(true);
    if (this.isCardOpens) {
      this.isCardOpens = false;
    } else {
      this.isCardOpens = true;
    }
  }
  @HostListener('document:click', ['$event'])
  handleClick(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isCardOpen = false;
      this.isCardOpens = false;
    }
  }
  openReport() {
    const dialogRef = this.dialog.open(ReportComponent, {
      width: '548px',
      panelClass: ['report', 'custom'],
      autoFocus: true,
      data: { scream: this.scream },
    });
  }

  goToResponses() {
    this.router.navigate(['/scream/'], {
      queryParams: {
        suid: this.scream.suid,
      },
    });
  }
  navigateToProfile() {
    const username =
      this.scream?.loginResponseDTO?.userName || this.profile?.userName;
    if (this.isUserLoggedIn) {
      if (username !== this.currentUserName) {
        this.router.navigate([`/${username}`]);
        window.scroll(0, 0);
      } else {
        this.router.navigate(['/my-profile']);
        window.scroll(0, 0);
      }
    }
  }
  navigateToBrandProfile() {
    const brandName = this.scream?.brandName;
    if (this.isUserLoggedIn) {
      if (brandName !== this.currentBrandName) {
        this.router.navigate([`/brand/${brandName}`]);
        window.scroll(0, 0);
      }
    }
  }
  openBottomSheetForIcons(icon: String) {
    if (
      this.dataTransferService.isLoggedin() ||
      this.dataTransferService.isBusinessLoggedin()
    ) {
      const config: MatBottomSheetConfig = {
        data: { scream: icon },
        panelClass: ['custom-bottom-sheet'],
      };
      this._bottomSheet.open(BurgerMenuComponent, config);
    } else {
      this.router.navigate(['/login']);
    }
  }

  updateScreamStatus(suid: string, status: string) {
    this.matDialog.open(ScreamStatusConfirmationComponent, {
      panelClass: ['border-radius'],
      autoFocus: false,
      data: { suid: suid, status: status },
    });
  }

  viewContact(suid: any) {
    this.matDialog.open(ViewContactComponent, {
      panelClass: ['border-radius'],
      autoFocus: false,
      data: { suid: suid, fullName: this.scream?.loginResponseDTO?.fullName },
      width: '348px',
    });
  }

  setFeeling() {
    if (
      this.scream.screamStatus === 'CLOSED' ||
      this.scream.screamStatus === 'AUTOCLOSED'
    ) {
      return 'was feeling';
    } else {
      return 'is feeling';
    }
  }
}
