<div class="d-flex justify-content-center align-items-center py-3">
  <div>
    <mat-icon class="material-icons-outlined mat-icon-height me-3"
      >history</mat-icon
    >
  </div>
  <div class="ms-2 w-100 truncate-ellipsis">
    <div *ngIf="search?.userName === 'null'" class="fw-400 fs-16">
      <div class="truncate-ellipsis w-100">
        <p class="m-0">{{ search?.fullName }}</p>
      </div>
    </div>
    <div *ngIf="search?.userName !== 'null'">
      <div class="d-flex align-items-center">
        <img
          class="profile-image-40 rounded-object profile-border {{
            search?.isVerified === 'brand' ? 'object-contain' : ''
          }}"
          [src]="
            search?.isVerified === 'user'
              ? search?.profile
                ? blobStorageLink + search?.profile
                : defaultImage
              : search?.isVerified === 'brand'
              ? search?.profile
                ? blobStorageLink + search?.profile
                : businessDefaultImage
              : null
          "
          alt="profile"
          mat-card-avatar
        />
        <div class="ps-2 d-flex flex-column">
          <div class="w-100 truncate-ellipsis">
            <p *ngIf="search?.isVerified === 'brand'" class="fw-600 fs-16 m-0">
              {{ search?.userName }}
              <span
                class="material-icons active-link icon-height fs-16"
                [ngClass]="search.subscription"
                *ngIf="search?.isUser === 'VERIFIED'"
              >
                verified
              </span>
            </p>
            <p *ngIf="search?.isVerified === 'user'" class="fw-600 fs-16 m-0">
              {{ search?.fullName }}
            </p>
          </div>
          <div class="w-100 truncate-ellipsis scream-icon-color">
            <p *ngIf="search?.isVerified === 'user'" class="fw-400 fs-12 m-0">
              {{ search?.userName }}
            </p>
            <p *ngIf="search?.isVerified === 'brand'" class="fw-400 fs-12 m-0">
              {{ search?.fullName }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
