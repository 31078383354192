import { Scream } from './../../core/interfaces/scream';
import { ScreamService } from './../../core/service/screamService/scream.service';
import {
  Component,
  OnInit,
  AfterContentInit,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '../../core/service/header/header.service';
import { TokenService } from './../../core/service/tokenService/token.service';
import { DataTransferService } from './../../core/service/data-transfer/data-transfer.service';
import { MatDialog } from '@angular/material/dialog';
import { ScreamUpsComponent } from './../../shared/scream-ups/scream-ups.component';
import { isPlatformBrowser } from '@angular/common';
import { BottomNavBarService } from './../../core/service/bottomNavBar/bottom-nav-bar.service';

@Component({
  selector: 'app-response',
  templateUrl: './response.component.html',
  styleUrls: ['./response.component.scss'],
})
export class ResponseComponent implements OnInit, AfterContentInit {
  screamShimmer: boolean = true;
  userImage = 'userImage';
  feedback: any;
  suid!: any;
  screamBySuid!: Scream;
  profile!: any;
  userProfile!: any;
  isContentVisible!: boolean;
  responses!: any;
  responseField!: boolean;
  screamUpCount!: number;
  platformId: Object;
  brandProfile!: any;
  mentionedBrands: any;
  queryParamValue: any;
  constructor(
    private readonly headerService: HeaderService,
    private activatedRoute: ActivatedRoute,
    private screamService: ScreamService,
    public router: Router,
    public route: ActivatedRoute,
    private token: TokenService,
    private readonly dataTransferService: DataTransferService,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) platformTd: Object,
    private bottomNavService: BottomNavBarService
  ) {
    this.platformId = platformTd;
  }
  ngOnInit() {
    this.suid = this.activatedRoute.snapshot?.queryParams['suid'];
    this.isContentVisible = this.token.getContent();
    this.profile = this.dataTransferService.getUserDetails()
      ? JSON.parse(this.dataTransferService.getUserDetails() || ' ')
      : '';
    this.brandProfile = this.dataTransferService.getBrandDetails()
      ? JSON.parse(this.dataTransferService.getBrandDetails() || ' ')
      : '';
    this.dataTransferService.screamUpCount$.subscribe((count) => {
      this.screamUpCount = count;
    });
    this.route.queryParams?.subscribe((params) => {
      if (params['suid'] != '') {
        this.suid = this.activatedRoute.snapshot?.queryParams['suid'];
        this.getScreamBySuid();
      }
    });
    this.token.setShowMore(false);
  }

  ngAfterContentInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.screen.width < 577) {
        setTimeout(() => {
          this.headerService.hide();
        }, 0);
      }
    }
  }

  sendExperienceAndStatus(response: any) {
    this.feedback = response;
    this.screamBySuid = this.screamBySuid || {};
    this.screamBySuid.screamStatus = this.feedback?.status;
    if (!this.feedback.experience || this.feedback.experience.length === 0) {
      this.screamBySuid.experienceStatus = null;
    } else {
      this.screamBySuid.experienceStatus = this.feedback.experience;
    }
  }

  goToHome() {
    this.router.navigate(['/home']);
  }

  getScreamBySuid() {
    this.screamService.fetchScreamBySuid(this.suid).subscribe(
      (data) => {
        this.screamShimmer = false;
        this.screamBySuid = data.body.responseBody;
        this.screamBySuid.showMore = this.token.getShowMore();
        this.screamUpCount = this.screamBySuid.screamUpsCount;
        this.responses = data.body.responseBody.responses;
        this.userProfile = data.body.responseBody.loginResponseDTO;
        this.mentionedBrands = data.body.responseBody.mentionedBrands;
        if (this.dataTransferService.isLoggedin()) {
          this.responseField =
            this.userProfile?.userName === this.profile?.userName
              ? true
              : false;
        } else if (this.dataTransferService.isBusinessLoggedin()) {
          this.mentionedBrands?.split(',').forEach((element: any) => {
            element.replaceAll('~', ' ') === this.brandProfile.brandName
              ? (this.responseField = true)
              : null;
          });
        }
      },
      () => {
        this.router.navigateByUrl('/page-not-found', { replaceUrl: true });
      }
    );
  }

  openDialogScreamUps(): void {
    if (
      this.dataTransferService.isLoggedin() ||
      this.dataTransferService.isBusinessLoggedin()
    ) {
      const dialogRef = this.dialog.open(ScreamUpsComponent, {
        data: { user: this.suid },
        panelClass: ['screamUp-dialog-box'],
      });
    } else {
      this.router.navigate(['/login']);
    }
  }

  isDateExceeded(): boolean {
    let targetDate = new Date(this.screamBySuid?.resolvedTime);
    let currentDate = new Date();
    const timeDifference = currentDate.getTime() - targetDate.getTime();
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    return hoursDifference < 48;
  }

  isScreamResolvable() {
    let isScreamResolved = this.screamBySuid?.screamStatus === 'RESOLVED';
    let isUserValid =
      this.profile.userName === this.screamBySuid?.loginResponseDTO?.userName;
    let isValidTime = this.isDateExceeded();
    this.hideBottomNav(isScreamResolved && isUserValid && isValidTime);
    return isScreamResolved && isUserValid && isValidTime;
  }

  hideBottomNav(isScreamResolvable: boolean) {
    if (isScreamResolvable) {
      this.bottomNavService.hide();
    }
  }
}
