<div
  class="d-flex justify-content-start"
  id="{{ 'container-' + emotionIndex }}"
>
  <mat-slider #slider [max]="max" [min]="min" [disableRipple]="true">
    <input matSliderThumb [(ngModel)]="value" (change)="getState(value)" />
  </mat-slider>

  <p
    class="fs-16 mt-2 profile-margin"
    [ngClass]="{
      'frustrated-color': emotionIndex <= 4,
      'satisfied-color': emotionIndex >= 5 && emotionIndex <= 6,
      'happy-color': emotionIndex >= 7
    }"
  >
    {{ feelings[emotionIndex] }}
  </p>
</div>
