import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfig } from '../../config/app.config';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  subscriptionId: any;

  private api = AppConfig.getAPIURI() + 'subscription';
  constructor(private http: HttpClient) {}

  postSubscription(request: any): Observable<any> {
    return this.http.post(this.api + `/create`, request, {
      observe: 'response',
    });
  }

  fetchPlans(): Observable<any> {
    return this.http.get(this.api + `/fetch-all-plans`, {
      observe: 'response',
    });
  }

  fetchCurrentPlans(): Observable<any> {
    return this.http.get(this.api + `/fetch-current-plan`, {
      observe: 'response',
    });
  }

  updatePlan(
    subscriptionId: string,
    params: { remaining_count: number; upgradePlanId: string }
  ): Observable<any> {
    return this.http.put(this.api + `/upgrade/${subscriptionId}`, null, {
      params: {
        remaining_count: params.remaining_count,
        upgradePlanId: params.upgradePlanId,
      },
      observe: 'response',
    });
  }

  cancelSubscription(subscriptionId: any): Observable<any> {
    return this.http.delete(this.api + `/cancel/${subscriptionId}`, {
      observe: 'response',
    });
  }
  fetchPaymentHistory(pageIndex: number, pageSize: number): Observable<any> {
    return this.http.get(this.api + '/fetch-payment-history', {
      params: {
        pageIndex: pageIndex.toString(),
        pageSize: pageSize.toString(),
      },
      observe: 'response',
    });
  }

  setSubscriptionId(id: any) {
    this.subscriptionId = id;
  }

  getSubscriptionId() {
    return this.subscriptionId;
  }
}
