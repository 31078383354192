<div class="d-flex py-1">
  <div class="d-flex align-items-center pe-4">
    <mat-icon class="material-icons-outlined mat-icon-height">search</mat-icon>
  </div>
  <div class="d-flex">
    <div class="d-flex align-items-center me-2">
      <img
        class="profile-image-40 rounded-object profile-border {{ result?.[6] === 'brand' ? 'object-contain' : '' }}"
        [src]="
    (result?.[3] !== null && result?.[6] === 'brand')
      ? blobStorageLink + result[3]
      : (result?.[3] !== null && result?.[6] === 'user')
        ? blobStorageLink + result[3]
        : (result?.[3] === null && result?.[6] === 'user')
          ? defaultImage
          : businessDefaultImage"
        alt="profile"
      />
    </div>
    <div class="d-flex flex-column">
      <div class="w-100 truncate-ellipsis">
        <p *ngIf="result?.[6] === 'user' " class="fw-600 fs-16 m-0">
          {{ result?.[2] }}
        </p>
        <p *ngIf="result?.[6] === 'brand' " class="fw-600 fs-16 m-0">
          {{ result?.[1] }}
          <span
            class="material-icons icon-height fs-16"
            [ngClass]="result[7]"
            *ngIf="result?.[4] === 'VERIFIED'"
          >
            verified
          </span>
        </p>
      </div>
      <div class="scream-icon-color">
        <p *ngIf="result?.[6] === 'brand' " class="fw-400 fs-12 m-0">
          {{ result?.[2] }}
        </p>
        <p *ngIf="result?.[6] === 'user' " class="fw-400 fs-12 m-0">
          {{ result?.[1] }}
        </p>
      </div>
    </div>
  </div>
</div>
