import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private token: any;
  private purpose: any;
  private content!: boolean;
  private userName: any;
  private address!: [];
  private showMore: boolean = false;
  private isFormValidSubject = new BehaviorSubject<boolean>(true);
  isFormValid$ = this.isFormValidSubject.asObservable();
  updateFormValidity(state: boolean) {
    this.isFormValidSubject.next(state);
  }
  setToken(token: any) {
    this.token = token;
  }
  getToken() {
    return this.token;
  }

  setPurpose(purpose: any) {
    this.purpose = purpose;
  }
  getPurpose() {
    return this.purpose;
  }
  setContent(content: boolean) {
    this.content = content;
  }
  getContent() {
    return this.content;
  }
  setUserName(userName: any) {
    this.userName = userName;
  }
  getUserName() {
    return this.userName;
  }

  getAddress() {
    return this.address;
  }
  setAddress(address: any) {
    this.address = address;
  }

  setShowMore(value: any) {
    this.showMore = value;
  }
  getShowMore() {
    return this.showMore;
  }
}
